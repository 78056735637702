<template>
  <div>
    <v-dialog v-model="edit.dialog" max-width="700">
      <v-card class="mx-auto">
        <v-card-title
          >Edit Bill/Delivery for
          {{ this.fuelText(this.edit.data ? this.edit.data.fuel : '') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="edit.dialog = !edit.dialog"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-container>
          <base-form
            submitButton="Update"
            :data="edit.data"
            @submit="updateHVACDelivery"
            @cancel="edit.dialog = false"
          >
          </base-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlt.dialog" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text>
          Are you sure? Do you want to delete this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteItem()">Yes</v-btn
          ><v-btn color="secondary" text @click="dlt.dialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-data-table
      :loading="loading"
      :headers="headers"
      :items="itemsComputed"
      class="elevation-1"
      sort-by="date"
      :sort-desc="true"
      dense
    >
      <template v-slot:[`header.fuel`]="{ header }">
        <span>
          <span> {{ header.text }} </span>
          <table-filter
            iconColor="white"
            itemStyle
            :items="sourceToObject(availableHVACSources)"
            v-model="filter"
          ></table-filter>
        </span>
      </template>

      <template v-slot:[`item.fuel`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" @click="setFilter(filter ? '' : item.fuel)">
              <fuel-chip
                small
                shortName
                :value="getSource(item.fuel)"
              ></fuel-chip>
            </span>
          </template>
          <span v-if="!filter">
            Click to see only
            {{ getSource(item.fuel) ? getSource(item.fuel).text : '' }} rows
          </span>
          <span v-else>Click to see all rows</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.btu`]="{ item }">
        <div>{{ fmtBtu(item.btu) }}</div>
      </template>

      <template v-slot:[`item.units`]="{ item }">
        <div v-if="item.units">
          {{ item.units }} {{ getSource(item.fuel).unitShort }}
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
          v-if="typeof item.bill == 'object' && item.bill.url"
          bottom
          class="d-inline"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="newWindow(item.bill.url)"
              ><slot name="icon">mdi-file-pdf</slot></v-icon
            >
          </template>
          <span
            ><slot name="tooltip">{{ item.bill.filename }}</slot></span
          >
        </v-tooltip>
        <v-tooltip bottom class="d-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="editItem(item)"
              ><slot name="icon">mdi-pencil</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Edit</slot></span>
        </v-tooltip>
        <v-tooltip bottom class="d-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDeleteDialog(item)"
              ><slot name="icon">mdi-trash-can</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Delete</slot></span>
        </v-tooltip>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import BaseForm from '@/components/HVAC/BaseForm'
import parcel from '@/mixins/parcel'
import hvac from '@/mixins/hvac'
import { mapGetters } from 'vuex'
export default {
  mixins: [utils, parcel, hvac],
  components: {
    BaseForm: BaseForm
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Fuel',
        align: 'center',
        value: 'fuel',
        class: 'primary white--text'
      },
      {
        text: 'Date',
        align: 'center',
        value: 'date',
        class: 'primary white--text'
      },
      {
        text: 'Quantity',
        align: 'center',
        value: 'units',
        class: 'primary white--text'
      },
      {
        text: 'MBTUs',
        align: 'center',
        value: 'btu',
        class: 'primary white--text'
      },
      {
        text: 'Cost $',
        align: 'center',
        value: 'cost',
        class: 'primary white--text'
      },
      {
        text: 'Cost $/per',
        align: 'center',
        value: 'cost_per_unit',
        class: 'primary white--text'
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'action',
        class: 'primary white--text',
        width: 120
      }
    ],
    edit: {
      dialog: false,
      index: null,
      data: null
    },
    dlt: {
      dialog: false,
      index: null
    }
  }),
  computed: {
    filter: {
      get() {
        return this.HVACFilter
      },
      set(data) {
        this.$store.commit('setHVACFilter', data)
      }
    },
    items() {
      return this.$store.getters.HVACDelivery
    },
    isMD() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    itemsComputed() {
      let res = this.copyObject(this.filteredHVAC)
      res = res.map(e => {
        e.cost_per_unit = this.costPerItem(e)
        e.btu = this.calcBtu(e, 'M')
        return e
      })
      return res
    },
    ...mapGetters([
      'HVACSources',
      'HVACFilter',
      'filteredHVAC',
      'availableHVACSources'
    ])
  },
  methods: {
    editItem(item) {
      this.edit.index = item.index
      this.edit.data = item
      this.edit.dialog = true
    },
    deleteItem() {
      if (this.dlt.index != -1) {
        this.$store.commit('removeHVACDelivery', this.dlt.index)
        this.$emit('updated')
        this.dlt.index = -1
        this.dlt.dialog = false
        this.saveHVACData()

        this.updateMetrics({ feature: 'hvac', action: 'delete' })
      }
    },
    updateHVACDelivery(data) {
      this.$store.commit('updateHVACDelivery', {
        index: this.edit.index,
        data: { ...data.formData }
      })
      this.edit.dialog = false
      this.saveHVACData()
      this.$emit('updated')

      this.updateMetrics({ feature: 'hvac', action: 'update' })
    },
    showDeleteDialog(item) {
      this.dlt.index = item.index
      this.dlt.dialog = true
    },
    setFilter(f) {
      this.filter = f
    },
    fmtBtu(val) {
      return this.fmtNum(Number(val).toFixed(0))
    }
  }
}
</script>

<style></style>
