<template>
  <div>
    <v-card>
      <v-container class="pa-3">
        <bar-chart
          ref="chart"
          v-bind="$attrs"
          :data="data"
          :labels="labels"
          :yTitle="yTitle"
          :backgroundColor="backgroundColor"
          :datasetLabel="sourceText"
        ></bar-chart>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import hvac from '@/mixins/hvac'
export default {
  mixins: [hvac],
  props: {
    source: {
      type: String
    },
    type: {
      type: String //quantity(Gallons, Therms, ...), $ cost, $ cost per {{unit}}, btu
    }
  },
  data: () => ({
    sortBy: 'date'
  }),
  computed: {
    filteredItems() {
      let res = this.copyObject(this.HVACDelivery)
      res = res.filter(e => e.fuel == this.source)
      res = res.sort((a, b) => new Date(a.date) - new Date(b.date))
      return res
    },
    sourceText() {
      return this.getSource(this.source).text
    },
    data() {
      let res = this.copyObject(this.filteredItems)
      switch (this.type) {
        case 'quantity':
          res = res.map(e => e.units)
          break
        case 'cost':
          res = res.map(e => e.cost)
          break
        case 'cost_per_unit':
          res = res.map(e => this.costPerItem(e))
          break
        case 'btu':
          res = res.map(e => this.calcBtu(e, 'M'))
          break
        default:
          res = []
          break
      }
      return res
    },
    labels() {
      let res = this.copyObject(this.filteredItems)
      res = res.map(e => e.date)
      return res
    },
    yTitle() {
      let s = this.getSource(this.source)
      if (!s) {
        return ''
      }
      if (this.type == 'quantity') {
        return s.unit
      } else if (this.type == 'cost') {
        return '$ Cost'
      } else if (this.type == 'cost_per_unit') {
        return `$ Cost per ${this.removeCharAt(s.unit, s.unit.length - 1)}`
      } else if (this.type == 'btu') {
        return `MBTU`
      } else {
        return ''
      }
    },
    backgroundColor() {
      let res = this.copyObject(this.filteredItems)
      res = res.map(e => {
        let source = this.getSource(e.fuel)
        if (source && source.style) {
          return source.style.color
        } else {
          return ''
        }
      })
      return res
    },
    ...mapGetters(['HVACFiltered', 'HVACFilter'])
  }
}
</script>

<style></style>
