<template>
  <v-row v-if="HVACFilter">
    <v-col cols="12" :md="colMd" v-if="showChart('quantity')">
      <hvac-chart
        :height="getHeight"
        :source="HVACFilter"
        type="quantity"
      ></hvac-chart>
    </v-col>
    <v-col cols="12" :md="colMd" v-if="showChart('btu')">
      <hvac-chart
        :height="getHeight"
        :source="HVACFilter"
        type="btu"
      ></hvac-chart>
    </v-col>
    <v-col cols="12" :md="colMd" v-if="showChart('cost')">
      <hvac-chart
        :height="getHeight"
        :source="HVACFilter"
        type="cost"
      ></hvac-chart>
    </v-col>
    <v-col cols="12" :md="colMd" v-if="showChart('cost_per_unit')">
      <hvac-chart
        :height="getHeight"
        :source="HVACFilter"
        type="cost_per_unit"
      ></hvac-chart>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import HvacChart from './HVACChart'
import hvac from '@/mixins/hvac'
export default {
  mixins: [hvac],
  components: {
    HvacChart
  },
  data: () => ({
    hideCharts: ['btu', 'quantity', 'cost_per_unit'],
    charts: ['quantity', 'btu', 'cost', 'cost_per_unit']
  }),
  computed: {
    getChartList() {
      let res = [...this.charts]
      res = res.filter(e => {
        if (this.showChart(e)) {
          return true
        } else {
          return false
        }
      })
      return res
    },
    colMd() {
      return Number(12) / Number(this.getChartList.length)
    },
    getHeight() {
      let screenSize = this.$vuetify.breakpoint.name
      let height = '300px'
      if (screenSize === 'md' && this.getChartList.length === 4) {
        height = '200px'
      }
      return height
    },
    ...mapGetters(['HVACFilter'])
  },
  methods: {
    showChart(type) {
      let s = this.getSource(this.HVACFilter)
      let res = true

      if (s) {
        if (!s.unit_field && this.hideCharts.some(e => e == type)) {
          res = false
        }
      }

      // don't show btu chart if greenhouseGas is not enabled on settings
      let userProfile = this.$store.getters.user.profile
      if (!userProfile.greenhouseGas && type == 'btu') {
        res = false
      }

      return res
    }
  }
}
</script>

<style></style>
