var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.edit.dialog),callback:function ($$v) {_vm.$set(_vm.edit, "dialog", $$v)},expression:"edit.dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Edit Bill/Delivery for "+_vm._s(this.fuelText(this.edit.data ? this.edit.data.fuel : ''))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.edit.dialog = !_vm.edit.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',[_c('base-form',{attrs:{"submitButton":"Update","data":_vm.edit.data},on:{"submit":_vm.updateHVACDelivery,"cancel":function($event){_vm.edit.dialog = false}}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title'),_c('v-card-text',[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c('v-spacer')],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.itemsComputed,"sort-by":"date","sort-desc":true,"dense":""},scopedSlots:_vm._u([{key:"header.fuel",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]),_c('table-filter',{attrs:{"iconColor":"white","itemStyle":"","items":_vm.sourceToObject(_vm.availableHVACSources)},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)]}},{key:"item.fuel",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({on:{"click":function($event){return _vm.setFilter(_vm.filter ? '' : item.fuel)}}},on),[_c('fuel-chip',{attrs:{"small":"","shortName":"","value":_vm.getSource(item.fuel)}})],1)]}}],null,true)},[(!_vm.filter)?_c('span',[_vm._v(" Click to see only "+_vm._s(_vm.getSource(item.fuel) ? _vm.getSource(item.fuel).text : '')+" rows ")]):_c('span',[_vm._v("Click to see all rows")])])]}},{key:"item.btu",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.fmtBtu(item.btu)))])]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [(item.units)?_c('div',[_vm._v(" "+_vm._s(item.units)+" "+_vm._s(_vm.getSource(item.fuel).unitShort)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(typeof item.bill == 'object' && item.bill.url)?_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.newWindow(item.bill.url)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-file-pdf")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v(_vm._s(item.bill.filename))])],2)]):_vm._e(),_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-pencil")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Edit")])],2)]),_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-trash-can")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Delete")])],2)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }