<template>
  <v-main>
    <v-container>
      <v-dialog v-model="dialogs.information" max-width="600">
        <v-card class="mx-auto">
          <v-card-title
            >Energy/Fuel Source
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogs.information = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <information-form
              @update="saveHVACData"
              @submit="dialogs.information = false"
              @cancel="dialogs.information = false"
            ></information-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <feature-breadcrumb :feature="fBreadcrumb">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" @click="dialogs.information = true">
                {{ fBreadcrumb.text }}
                <hvac-chip :value="HVACInformation"></hvac-chip>
              </span>
            </template>
            <span>Energy Fuel Source</span>
          </v-tooltip>
        </template>
        <template #action>
          <ParcelGalleryButton
            v-model="gallery"
            class="ml-1"
            icon
            :count="galleryCount"
          />
        </template>
      </feature-breadcrumb>
      <v-row>
        <v-col cols="12">
          <FeatureGallery
            feature="Energy"
            v-model="gallery"
            :count.sync="galleryCount"
          />
          <v-card>
            <v-card-title
              v-show="this.selectedSources.length"
              class="primary--text cursor-pointer"
              @click="onClickShowHVACForm"
              ><v-btn color="primary" :disabled="!HVACFilter"
                >Add Bill/Delivery for
                {{ this.fuelText(this.HVACFilter) || '_____' }}
                <v-icon>{{
                  showHVACForm && this.HVACFilter ? 'mdi-minus' : 'mdi-plus'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-container class="mt-n3">
              <v-expand-transition>
                <div class="ml-5 mr-5 mb-2">
                  <add-form></add-form>
                </div>
              </v-expand-transition>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <hvac-chart-list></hvac-chart-list>

      <div class="mt-3">
        <hvac-delivery-history></hvac-delivery-history>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import AddForm from '@/components/HVAC/AddForm'
import InformationForm from '@/components/HVAC/InformationForm'
import HvacDeliveryHistory from '@/components/HVAC/DeliveryHistory'

import HvacChartList from '@/components/HVAC/ChartList'

import { mapGetters, mapMutations } from 'vuex'
import parcel from '@/mixins/parcel'
import hvac from '@/mixins/hvac'
export default {
  mixins: [parcel, hvac],
  components: {
    AddForm,
    InformationForm,
    HvacDeliveryHistory,
    HvacChartList
  },
  data: () => ({
    gallery: false,
    galleryCount: 0,
    fBreadcrumb: {
      text: 'Energy',
      to: '?info=true'
    },
    loading: true,
    dialogs: {
      information: false
    }
  }),
  computed: {
    showHVACForm: {
      get() {
        return this.$store.getters.showHVACForm
      },
      set(val) {
        this.$store.commit('setShowHVACForm', val)
      }
    },
    HVACData() {
      return this.$store.getters.HVACData
    },
    address() {
      if (!this.parcel) {
        return ''
      }
      return this.parcel.street_number + ' ' + this.parcel.street_name
    },
    url() {
      return this.$route.query.info
    },
    infoDialog() {
      return this.dialogs.information
    },
    firstAvailableHVAC() {
      if (this.selectedSources.length) {
        return this.selectedSources[0].text
      } else {
        return ''
      }
    },
    ...mapGetters([
      'latestParcel',
      'HVACLoading',
      'HVACInformation',
      'availableHVACSources',
      'HVACFilter'
    ])
  },
  async mounted() {
    let data = await this.getHVACData(this.$route.params.id)
    if (data && data.data.length == 0) {
      this.showHVACForm = true
    } else {
      if (this.xs) {
        this.showHVACForm = false
      }
    }
    if (data) {
      this.$store.commit('setHVACData', data)
    } else {
      this.$store.commit('HVACLoading', false)
    }
    this.loading = false
    this.checkInformation()
    this.checkURL()
  },
  destroyed() {
    this.$store.dispatch('resetHVAC')
  },
  methods: {
    ...mapMutations({
      setHVACFilter: 'setHVACFilter',
      setShowHVACForm: 'setShowHVACForm'
    }),
    checkURL() {
      if (this.url) {
        this.dialogs.information = true
      }
    },
    checkInformation() {
      const checked = this.$route.query.checked;
      if (checked) {
        return
      }
      if (!this.HVACInformation) {
        this.dialogs.information = true
      }
    },
    handleDialog() {
      if (this.infoDialog) {
        let path = this.$route.path + '?info=true'
        this.routePush(path)
      } else {
        this.routePush(this.$route.path + '?checked=true')
      }
    },
    onClickShowHVACForm() {
      if (this.HVACFilter) {
        this.showHVACForm = !this.showHVACForm
      }
    }
  },
  watch: {
    xs() {
      if (!this.xs) {
        this.dialogs.addData = true
      }
    },
    infoDialog: {
      handler: 'handleDialog'
    }
  }
}
</script>

<style></style>
