import { storage } from '../firebase/db'
export default {
  methods: {
    async updateMetadata(url, metadata) {
      let ref = await storage.refFromURL(url)
      let meta = await ref.getMetadata()
      let customMetadata = meta.customMetadata
      customMetadata = { ...customMetadata, ...metadata }
      await ref.updateMetadata({ customMetadata })
    }
  }
}
