<script>
import BaseForm from './BaseForm.vue'
export default {
  extends: BaseForm,
  methods: {
    submit() {
      this.$store.commit('addHVACDelivery', this.formData)

      this.saveHVACData()
      this.$emit('submit')
      this.setDefaultValue()

      this.updateMetrics({ feature: 'hvac', action: 'create' })
    }
  }
}
</script>
